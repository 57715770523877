import React from 'react';

import mainIcon from '../../assets/images/icons/icon-lg-SSAE18.png';
import icon1 from '../../assets/images/icons/icon-template-sys.png';
import icon2 from '../../assets/images/icons/icon-scorecards.png';
import icon3 from '../../assets/images/icons/icon-audit.png';
import icon4 from '../../assets/images/icons/icon-evid-repo.png';
import process from '../../assets/images/services/process-line.png';
import iconcheck from '../../assets/images/services/icon-check-process.png';

const SSAE18 = () => {
  return (
    <section className='services-details-area ptb-100'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <div className='row'>
              <div className='d-flex col-lg-4 justify-content-center align-items-start'>
                <img src={mainIcon} alt='SSAE18 (SOC 2)' style={{ maxWidth: '300px' }} />
              </div>
              <div className='col-lg-8'>
                <h1>SSAE18 (SOC 2) </h1>
                <p>
                  The AICPA issues the Trust Services Criteria (TSC) that outlines COSO objectives to be implemented by
                  organizations wishing to issue a SSAE18 SOC 2 report. The TSC defines objectives within the following
                  domains.
                </p>
                <ul>
                  <li>Security</li>
                  <li>Availability</li>
                  <li>Processing Integrity</li>
                  <li>Confidentiality</li>
                  <li>Privacy</li>
                </ul>
                <p>
                  Organizations that implement SOC 2 are required to design a set of controls to meet the TSC objectives
                  and generate their management attestation and system description report.
                </p>
              </div>
            </div>

            <hr className='mtb-70' />
            <h1 className='subheading pb-4'>Why SOC 2?</h1>
            <div className='col-lg-8 offset-lg-2'>
              <p>
                More and more organizations are requiring service providers to comply with SOC 2 and issue reports as it
                helps them understand the security posture of their providers control environment and service.
              </p>
              <p>
                It is often a competitive advantage to sell more services over their competition for providers that do
                not have SOC 2 certification.
              </p>
            </div>
            <hr className='mtb-70' />
            <h4 className='text-center ptb-40 '>Tools and Templates built on Atlassian</h4>
            <div className='row'>
              <div className='col-lg-3 col-sm-6'>
                <div className='single-solutions-box'>
                  <div className='icon'>
                    <img src={icon1} style={{ width: 120 }} alt='Document Templates' />
                  </div>
                  <p>System Description Template</p>
                </div>
              </div>

              <div className='col-lg-3 col-sm-6'>
                <div className='single-solutions-box'>
                  <div className='icon'>
                    <img src={icon2} style={{ width: 120 }} alt='Risk Assessment' />
                  </div>
                  <p>SOC 2 Control Scorecards</p>
                </div>
              </div>

              <div className='col-lg-3 col-sm-6 offset-lg-0 '>
                <div className='single-solutions-box'>
                  <div className='icon'>
                    <img src={icon3} style={{ width: 120 }} alt='Control Scorecards' />
                  </div>
                  <p>Audit program</p>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6 offset-lg-0 '>
                <div className='single-solutions-box'>
                  <div className='icon'>
                    <img src={icon4} style={{ width: 120 }} alt='Audit Program' />
                  </div>
                  <p>Evidence repository</p>
                </div>
              </div>
            </div>
            <p className='text-center col-lg-6 offset-lg-3'>
              Agility is pre-configured with all the tools and templates you need to implement your SOC 2 program.
            </p>
            <p className='text-center col-lg-6 offset-lg-3'>
              Easily track program status, control compliance, document status and remediation all within your or Ekko
              hosted Atlassian Cloud instance.
            </p>
            <div className='process-rule'>
              <img src={process} className='mtb-70' alt='process' />
            </div>
            {/* <h1 className="mtb-70 text-center" >Start your SOC 2 Program </h1> */}
            <div className='row'>
              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' /> Start your SOC 2 Program
                  </h3>
                  <p className='step-subtitle'>Agility provides you everything you need to start your SOC 2 program</p>
                  <ul className='step-sublist'>
                    <li>Scope guidance and templates</li>
                    <li>Defined, roadmap project plan and milestones</li>
                    <li>Control remediation dashboards and reports</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' /> Create Information Security Policies &
                    Procedures
                  </h3>
                  <p className='step-subtitle'>Pre-built SOC 2 compliant policy templates</p>
                  <ul className='step-sublist'>
                    <li>All policies required to support SOC 2 domains</li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' />
                    Gap Analysis and Scorecard
                  </h3>
                  <p className='step-subtitle'>Establish your control baseline and program starting point</p>
                  <ul className='step-sublist'>
                    <li>Generate control scorecard and reports</li>
                    <li>Define your remediation and implementation roadmap</li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' />
                    Select Certification CPA firm
                  </h3>
                  <p className='step-subtitle'>
                    SOC 2 requires an external CPA firm to conduct your certification audit
                  </p>
                  <ul className='step-sublist'>
                    <li>SOC 2 Readiness assessment</li>
                    <li>SOC 2 Type 1 Design Assessment</li>

                    <li>SOC 2 Type 2 Attestation Audit</li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' /> Program Implementation
                  </h3>
                  <p className='step-subtitle'>
                    Agility provides you all the tools to implement, track and remediate your Information Security
                    program
                  </p>
                  <ul className='step-sublist'>
                    <li>Remediation and control improvement task tracking</li>
                    <li>Reporting and dashboards</li>
                    <li>Reminders and scheduling</li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' /> Internal Readiness
                  </h3>
                  <p className='step-subtitle'>Conduct your Internal readiness assessment inside Agility</p>
                  <ul className='step-sublist'>
                    <li>SOC 2 readiness assessment</li>
                    <li>Pre-built audit test cases</li>
                    <li>Generate and manage control evidence</li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' /> Certification
                  </h3>
                  <p className='step-subtitle'>
                    Conduct your SOC 2 certification audit with your selected registrar audit firm
                  </p>
                  <ul className='step-sublist'>
                    <li>Get certified</li>
                    <li>Conduct annual audits</li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-6 p-4'>
                <div className='step-panel'>
                  <h3 className='step-title'>
                    <img src={iconcheck} className='check-icon' alt='icon' /> SOC 2 Maintenance
                  </h3>
                  <p className='step-subtitle'>Agility helps you maintain your ISMS</p>
                  <ul className='step-sublist'>
                    <li>Pre-built SOC 2 task templates</li>
                    <li>Schedule and track ongoing activities</li>
                    <li>Maintain evidence</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SSAE18;

import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Services/PageBanner2'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/SSAE18'

 
const Details = () => {
    return (
        <Layout>
           <div className="darknav"><Navbar /></div>
            <PageBanner
                pageTitle="SSAE18 (SOC 2)" 
                // homePageText="Home" 
                // homePageUrl="/" 
                activePageText="Service Details" 
            />
            <ServiceDetailsContent /> 

            <Footer />
        </Layout>
    );
}

export default Details